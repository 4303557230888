<template>
    <div v-if="message" class="document plugin" :class="[type, customClass, $mq, { 'portrait-deployed': portraitDeployed }]">
        <div class="andy">
            <LottieAnimation v-if="!typeFinish" :stopAnimation="stopAnimation" :animationName="'Andy-Hablando.json'"></LottieAnimation>
            <LottieAnimation v-if="second" :frameStop="type == 'error' ? 44 : 68" :animationName="type == 'error' ? 'alexhead_04.json' : 'alexhead_08.json'"></LottieAnimation>
        </div>
        <div class="message keep-format">
            <!-- <div class="preview hide-scrollbar" v-html="message"></div> -->
            <vue-typed-js :strings="[message]" @onComplete="messageRendered" :loop="false" :typeSpeed="2" :startDelay="0" :showCursor="false">
                <div class="preview hide-scrollbar typing"></div>
            </vue-typed-js>
            <template v-if="hasActions && ['desktop'].includes($mq)">
                <div v-if="buttonLabel != undefined && !loader" class="action" @click="actionButton">
                    {{ buttonLabel }}
                </div>
                <div v-else-if="loader && !['portrait', 'landscape'].includes($mq)" class="action loader"></div>
            </template>
            <div v-if="['portrait', 'landscape'].includes($mq) && hasActions" class="portrait-deploy" @click="portraitDeployed = !portraitDeployed"></div>
        </div>
        <template v-if="hasActions && (['desktop'].includes($mq) || (['portrait', 'landscape'].includes($mq) && portraitDeployed))">
            <div v-if="buttonLabel != undefined && !loader" class="action small-screen" @click="actionButton">
                {{ buttonLabel }}
            </div>
            <div v-else-if="loader" class="action loader small-screen"></div>
        </template>
    </div>
</template>
<script>
import LottieAnimation from '../../layout/LottieAnimation.vue'
import Vue from 'vue'
import VueTypedJs from 'vue-typed-js'
Vue.use(VueTypedJs)

export default {
    components: { LottieAnimation },
    props: {
        message: { type: undefined },
        type: { type: String },
        buttonLabel: undefined,
        loader: { default: false },
        hasActions: { default: true },
        customClass: { type: [String, Array, Object, Function], default: '' },
        portraitDeployed: { default: false }
    },
    data() {
        return {
            typeFinish: false,
            second: false,
            stopAnimation: false
        }
    },
    computed: {
        isApp() {
            return this.$store.getters['getIsApp']
        }
    },
    methods: {
        actionButton() {
            this.$emit('buttonAction')
        },
        // This is executed for android devices to open the links in new tab of chrome
        messageRendered() {
            if (this.isApp) {
                document
                    .getElementsByClassName('preview')[0]
                    .getElementsByTagName('a')
                    .forEach((e) => {
                        logError(e.href)
                        var link = e.href
                        // e.href = ''
                        e.setAttribute('onclick', 'return openLinkInApp(event,"' + link + '")')
                    })
            }
            var self = this
            self.stopAnimation = true
            setTimeout(() => {
                self.typeFinish = true
            }, 500)
            setTimeout(() => {
                self.second = true
            }, 500)
        }
    }
}
</script>

<style lang="scss">
.document.plugin {
    @include display-flex();
    @include justify-content();
    @include align-items();
    width: 100%;
    padding: 5px 15px 5px 15px;
    margin-top: 6px;

    .andy {
        // @include background($image: img('alexhead_04.svg'), $size: contain);
        width: 60px;
        height: 60px;
        display: inline-block;
        margin-right: 6px;
    }

    .message {
        @include border-radius(3px);
        @include font-size(sm);
        line-height: 25px;
        font-family: $text;
        color: $main-s40;
        padding: 9px 12px !important;
        display: inline-block;
        background-color: $warning-t30;
        overflow: hidden;
        width: 100%;
        max-width: calc(100% - 40px - 6px);
        min-height: 50px;
        height: auto;
        max-height: 200px;
        display: inline-flex;
        align-items: center;
        max-width: calc(100% - 40px - 6px);

        .typed-element {
            width: 100%;
        }

        b {
            color: inherit;
            font-size: inherit;
            font-family: $text-bold;
        }

        ul {
            color: inherit;
            font-size: inherit;
            list-style: disc;
            padding-left: 30px;
        }

        li {
            color: inherit;
            font-size: inherit;
        }

        span {
            display: inline-block;
        }

        .preview,
        .read-more,
        .typing {
            @include font-size(sm);
            font-family: $text-light;
            display: inline-block;
            width: auto;
            font-size: 18px;
            min-width: 120px;
            height: auto;
            max-height: 50px;
            overflow-y: auto;
        }
        .preview {
            max-height: 200px;

            a {
                font-size: inherit;
                font-weight: bold;
                text-decoration: underline;
                // font-family: inherit;
                color: inherit;
            }
        }

        .read-more {
            font-family: $text;
            color: $main-text;
            margin-left: 12px;
            width: 80px;
            text-align: center;
        }

        .windows-error {
            a {
                font-weight: bold;
                color: black;
                text-decoration: underline;
            }
        }
    }
    .action {
        @include border-radius(3px);
        @include font-size(sm);
        @include text-ellipsis();
        width: 100%;
        min-width: 40px;
        max-width: fit-content;
        font-family: $text;
        display: inline-block;
        height: 40px;
        // border: 1px solid;
        background-color: $neutro-light;
        line-height: 40px;
        margin-left: 10px;
        padding: 0 10px;
        cursor: pointer;

        &.loader {
            background-color: $neutro;
            @include background($image: img('loader_light.svg'), $size: 25px, $position: center center);
            background-repeat: no-repeat;
            width: 50px;
        }
        &.small-screen {
            display: none;
        }
    }

    // CUSTOM STYLES FOR EACH TYPE
    &.warning {
        .andy {
            // @include background($image: img('alexhead_04.svg'), $size: contain);
        }

        .message {
            color: $warning-s40;
            background-color: $warning-t30;

            .preview {
                width: fit-content;
                color: $warning-s40;
            }
        }
    }
    &.info {
        .andy {
            // @include background($image: img('alex_carita_10.svg'), $size: contain);
        }

        .message {
            background-color: $info-t30;

            div {
                // font-family: inherit;
                color: inherit;
            }
        }
    }
    &.error {
        .andy {
            // @include background($image: img('alexhead_03.svg'), $size: contain);
        }

        .message {
            padding: 6px 10px !important;
            // background-color: $error-t30;
            background-color: $error-s10;

            .preview,
            .read-more {
                // color: $error-s70;
                color: #fff;
                width: 100%;
            }
        }

        &.info {
            .message {
                background-color: $info-t30;
            }
        }

        &.has-issue {
            .message {
                background-color: tint($error, 70%);
                color: $error-dark;

                .read-more {
                    color: $error-dark;
                }
            }
        }
    }

    // Custom class
    &.custom-alert-task {
        @include display-flex();
        @include justify-content(flex-start);
        width: 100%;
        padding: 5px 0;
        margin-top: 6px;
        height: unset;
        height: 55px;

        .andy {
            // @include background($image: img('alexhead_04.svg'), $size: contain, $position: left bottom);
            width: rem(40px);
            height: rem(40px);
            display: inline-block;
            margin-right: 6px;
        }

        .message {
            @include display-flex();
            @include border-radius(3px);
            color: $main-s40;
            padding: 0 6px !important;
            display: inline-block;
            background-color: tint($warning, 70%);
            overflow: hidden;
            width: auto;
            max-width: calc(100% - #{rem(40px)} - #{rem(6px)});
            height: unset;
            min-height: unset;
            .preview {
                @include display-flex();
                @include align-items(center);
                @include font-size(s);
                line-height: 25px;
                height: 40px;
                font-family: $text;
            }
        }
    }
    &.login {
        margin-top: 0px !important;
        padding: 0px;
        height: 100%;
        max-height: $menu-top-tools-height;

        .andy {
            @include background($image: img('alexhead_06.svg'), $size: contain, $position: left bottom);
            width: 60px;
            height: 60px;
            display: inline-block;
            margin-right: 6px;
        }
        .message {
            padding-left: 12px;
            padding-right: 12px;
            background-color: $info-t30;
            max-height: $menu-top-tools-height;

            .preview {
                @include font-size(ml);
                font-family: $text-bold;
                color: $info-s70;
            }
        }
    }

    @include media('<=tablet') {
        @include flex-wrap(wrap);
        @include justify-content(flex-start);

        .message {
            max-width: calc(100% - 60px - 6px);
        }
        .action:not(.small-screen) {
            display: none;
        }
        .action.small-screen {
            @include display-flex();
            @include default-box-shadow($o: 0.1, $b: 3px);
            margin-left: 60px + 6px !important;
            margin-top: 9px;
        }
        .small-screen {
            @include display-flex();
            @include default-box-shadow($o: 0.1, $b: 3px);
            margin-left: 60px + 6px !important;
            margin-top: 9px;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.document.plugin.portrait,
.document.plugin.landscape {
    @include justify-content(flex-end);
    @include flex-wrap(wrap);
    position: relative;
    max-height: none;
    height: fit-content;
    width: 100%;
    max-width: none;
    margin: 10px auto !important;
    // margin: 40px 0 !important;
    margin-bottom: 10px !important;
    padding: 0;

    &.landscape {
        width: calc(100% - 60px - 6px);
    }

    .andy {
        // @include background($image: img('alexhead_04.svg'), $size: contain, $position: center center);
        width: 35px;
        height: 35px;
        margin-left: 13px;
        display: inline-block;
        position: absolute;
        // top: -30px;
        // left: 10px;
        top: 8px;
        left: -10px;
    }

    .message {
        @include display-flex();
        // @include flex-direction(column);
        @include align-items(center);
        width: 100%;
        overflow: inherit;
        max-height: none;
        max-width: none;
        padding: 0 !important;
        font-family: $text;

        .typed-element {
            @include display-flex();
            @include text-ellipsis();
            width: 100%;
            width: calc(100% - 50px) !important;
            overflow: hidden;
            min-height: 55px;
            height: unset;
        }

        .preview,
        .read-more {
            overflow: hidden;
            @include text-ellipsis();
            @include border-radius(3px);
            @include font-size(s);
            display: inline-block;
            // padding: 15px 11px 11px 32px !important;
            padding: 15px 11px 11px 36px !important;
            font-family: $text;
            width: 100% !important;
            height: auto;
            max-width: none;
            max-height: unset;
            overflow: inherit;
            min-height: none;
            height: 55px;

            * {
                @include display-inline-flex();
                height: 0px;
                font-family: $text;
                @include font-size(s);
            }

            br {
                display: contents;
            }
        }

        .portrait-deploy {
            @include background($image: img('down_inactive_light.svg'), $size: 20px, $position: center center);
            @include border-radius(4px);
            @include display-flex();
            @include justify-content(center);
            @include align-items();
            align-self: flex-start;
            margin-top: 7px;
            width: 40px;
            height: 40px;
            background-color: rgba(0, 0, 0, 0.3);
            cursor: pointer;
        }
    }

    .action {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: 66px !important;
        margin-top: 9px;
        @include font-size(xs);
        font-family: $text-bold;
        // background-color: $error-s50;
        text-transform: uppercase;
        // color: #fff;
        margin-top: 5px;
    }

    &.error {
        .andy {
            // @include background($image: img('alexhead_03.svg'), $size: contain);
        }

        .message {
            background-color: $error-s10 !important;
            // width: 100%;

            .preview,
            .read-more {
                color: #fff;
                width: 100%;
                background-color: $error-s10;
            }
        }

        // &.info {
        //     .message {
        //         background-color: #c8cee0;
        //     }
        // }

        &.has-issue {
            .message {
                background-color: tint($error, 70%);
                color: $error-dark;

                .read-more {
                    color: $error-dark;
                }
            }
        }

        .action {
            background-color: $error-s50;
            color: #fff;
        }
    }

    &.warning {
        .andy {
            // @include background($image: img('alexhead_04.svg'), $size: contain);
        }

        .message {
            .preview {
                color: $warning-s40;
                background-color: $warning-t30;
                // width: fit-content;
            }
        }

        .action {
            background-color: $warning-s50;
            color: #fff;
        }
    }
    &.info {
        .andy {
            // @include background($image: img('alex_carita_10.svg'), $size: contain);
        }

        .message {
            .preview {
                background-color: $info-t30;
                // width: fit-content;
            }
            div {
                font-family: inherit;
                color: inherit;
            }
        }

        .action {
            background-color: $info-s50;
            color: #fff;
        }
    }

    &.portrait-deployed {
        // margin: 40px 0 !important;

        .andy {
        }
        .message {
            // @include flex-direction(column);

            .typed-element {
                text-overflow: unset;
                white-space: break-spaces;
                // width: 100% !important;
                height: unset;
            }

            .preview,
            .read-more {
                // padding: 20px 11px 11px !important;
                text-overflow: unset;
                height: unset;
                display: block !important;
                // width: 100% !important;
                white-space: break-spaces;

                * {
                    height: unset;
                }

                br {
                    display: inline-block;
                }
            }
            .preview {
                span {
                    display: inline;
                    font-family: $text-bold;
                }
            }

            .preview {
                span {
                    display: inline;
                    font-family: $text-bold;
                }
            }
        }

        .portrait-deploy {
            @include background($image: img('up_inactive_light.svg'), $size: 20px, $position: center center);
        }
    }
}

.document.plugin.warning.portrait,
.document.plugin.info.portrait {
    .andy {
        margin-left: 13px;
        height: 35px;
        width: 35px;
    }
}
</style>
